import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  Involvement,
  Visit,
  TitleContent,
  WorkedWith,
  Project,
  Car,
} from "../components/parts"

const AutodeskDynamo = () => {
  return (
    <Layout title="Autodesk Dynamo">
      <Title
        heading="Autodesk Dynamo"
        sub="Open-source graphical programming for architects"
      />
      <Overview
        project="autodesk-dynamo"
        text={[
          "Dynamo is a visual programming environment that enables designers to explore parametric conceptual designs and automate tasks.",
          "I was involved in detailed interaction design and user studies right from the initial conceptual design stage of the visual programming tool.",
        ]}
      />
      <div className="section-bottom">
        <img src="/images/autodesk-dynamo/compare.png" alt="Comparision" />
      </div>
      <div className="section-bottom">
        <img src="/images/autodesk-dynamo/shell.png" alt="Shell" />
      </div>
      <hr style={{ marginBottom: 42 }} />
      <TitleContent title="Node to code & back">
        <p>Node to code helps beginner programmers learn to code.</p>
        <p>Seasoned programmers can still write code as they usually would.</p>
        <p>Converting to code helps in solving some of the node 'noodle'.</p>
      </TitleContent>
      <div className="section-bottom">
        <img
          src="/images/autodesk-dynamo/code-to-node.png"
          alt="Code to node"
        />
      </div>
      <hr style={{ marginBottom: 42 }} />
      <TitleContent title="Visual library">
        <p>
          Created approximately 1140 icons along with visual organisation to
          help users find the relevant functionality faster and to make them
          aware of the capabilities.
        </p>
      </TitleContent>
      <div className="section-bottom">
        <img
          src="/images/autodesk-dynamo/visual-library.png"
          alt="Visual library"
        />
        <img src="/images/autodesk-dynamo/collection.png" alt="icons" />
      </div>
      <hr style={{ marginBottom: 42 }} />
      <TitleContent title="Canvas annotations">
        <p>
          Large graphs get messy and identifying specific parts of the graph
          becomes unusually difficult.
        </p>
        <p>
          With canvas annotations, parts of the graph can be visually grouped
          and also color coded with functional descriptions.
        </p>
        <div className="section-top">
          <img
            src="/images/autodesk-dynamo/canvas-annotation-1.png"
            alt="Canvas annotations"
            className="section-bottom"
          />
          <img
            src="/images/autodesk-dynamo/canvas-annotation-2.png"
            alt="Canvas annotations"
          />
        </div>
      </TitleContent>
      <hr style={{ marginBottom: 42 }} />
      <TitleContent title="Node interactions">
        <p>
          Dynamo is designed to react to every user input to give a fluid
          experience.
        </p>
      </TitleContent>
      <div className="section-bottom">
        <img
          src="/images/autodesk-dynamo/node-interaction-1.png"
          alt="Node interaction"
        />
      </div>
      <p>
        With the ability to express your parametric designs in code, Dynamo
        nodes are loaded with convenient ways of adding inputs to aid quick
        exploration.
      </p>
      <div className="section-bottom">
        <img
          src="/images/autodesk-dynamo/node-interaction-2.png"
          alt="Node interaction"
        />
      </div>
      <p>
        Nodes also provide contextual extensions to help the user with what to
        do next or to change their mind from its current state.
      </p>
      <div className="section-bottom">
        <img
          src="/images/autodesk-dynamo/node-interaction-3.png"
          alt="Node interaction"
        />
      </div>
      <hr style={{ marginBottom: 42 }} />
      <TitleContent title="Package manager">
        <p>Package manager helps Dynamo users share their workflows quickly.</p>
        <p>
          Users can upload specific files or share their components right from
          canvas.
        </p>
      </TitleContent>
      <div className="section-bottom">
        <img
          src="/images/autodesk-dynamo/package-manager.png"
          alt="package manager"
        />
      </div>
      <hr style={{ marginBottom: 42 }} />
      <TitleContent title="Geometry models">
        <p>.. made with Dynamo</p>
        <div className="section-bottom">
          <Car
            images={[1, 2, 3, 4].map(
              i => `/images/autodesk-dynamo/model-${i}.png`
            )}
            bordered
          />
        </div>
      </TitleContent>
      <Toolset project="autodesk-dynamo" />
      <Project heading="Dynamo packages" sub="Packages developed using C#" />
      <TitleContent title="Dynamo to Illustrator">
        <img
          src="/images/autodesk-dynamo/dyn-illustrator.png"
          alt="illustrator"
        />
      </TitleContent>
      <TitleContent title="Grapher">
        <img src="/images/autodesk-dynamo/grapher.png" alt="grapher" />
      </TitleContent>
      <Toolset tools={["csharp"]} />
      <Involvement project="autodesk-dynamo" />
      <Visit project="autodesk-dynamo" />
      <WorkedWith project="autodesk-dynamo" />
    </Layout>
  )
}

export default AutodeskDynamo
